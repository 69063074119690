$color-additional: #999999;
$color-additional-hover: darken($color-additional, 10%);

$color-yellow: #EFA454;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: #c88ffa;

$color-red: #C3485F;
$color-red-hover: darken($color-red, 10%);

$color-purple: #6146FB;
$color-purple-hover: darken($color-purple, 10%);

$color-alt-green: #91C753;
$color-alt-green-hover: darken($color-alt-green, 10%);

$color-alt-dark-green: #43A040;
$color-alt-dark-green-hover: darken($color-alt-dark-green, 10%);

$color-blue: #70bbfd;
$color-blue-hover: darken($color-blue, 10%);

$color-orange: #E87B50;
$color-orange-hover: darken($color-orange, 10%);

$color-dark-red: #960000;
$color-dark-red-hover: darken($color-dark-red, 10%);

$color-off-blue: #333598;
$color-off-blue-hover: darken($color-off-blue, 10%);

$color-gray: #787985;
$color-light-gray: #d8dfe9;

$color-green: #91BE23;
$color-green-hover: darken($color-green, 10%);

$color-dark-green: #024641;
$color-dark-green-hover: lighten($color-dark-green, 10%);

$themes: (
  light: (
    colorPrimary: $color-green,
    colorPrimaryHover: $color-green-hover,
    colorAccent: $color-dark-green,
    colorAccentHover: $color-dark-green-hover,
    colorBackground: white,
    colorBackgroundAlt: white,
    colorBackgroundBody: #f2f4f7,
    colorText: #323232,
    colorTextAdditional: #323232,
    cornerLogoImg: url(../../shared/img/logo/limitless/Limitless-Amazon-AdTech-Platform-Logo-Wide-Bright-Green-900x200-20210209.png),
    largeLogoImg: url(../../shared/img/logo/limitless/Limitless-Amazon-AdTech-Platform-Logo-Wide-Bright-Green-900x200-20210209.png),
    colorHover: #fafbfe,
    colorBorder: #eff1f5,
    colorIcon: #dddddd,
    imgInvert: invert(0%),
    colorFieldsBorder: #f2f4f7,
    colorBubble: rgba(242, 244, 247, 0.65),
    colorBubbleActive: rgba(234, 238, 255, 0.6),
    colorScrollbar: #B4BFD0,
    colorFitness: #646777,
    colorHighlightGutterSelected: rgba(145, 190, 35, 0.65)
  ),
  dark: (
    colorPrimary: $color-green,
    colorPrimaryHover: $color-green-hover,
    colorAccent: $color-dark-green,
    colorAccentHover: $color-dark-green-hover,
    colorBackground: #232329,
    colorBackgroundAlt: #808080,
    colorBackgroundBody: #2a2a31,
    colorText: #FFFFFF,
    colorTextAdditional: #F4F4F4,
    cornerLogoImg: url(../../shared/img/logo/limitless/Limitless-Amazon-AdTech-Platform-Logo-Wide-Bright-Green-900x200-20210209.png),
    largeLogoImg: url(../../shared/img/logo/limitless/Limitless-Amazon-AdTech-Platform-Logo-Wide-Bright-Green-900x200-20210209.png),
    colorHover: #38373f,
    colorBorder: #333246,
    colorIcon: #605f7b,
    imgInvert: invert(100%),
    colorFieldsBorder: #33333a,
    colorBubble: rgba(68, 79, 97, 0.65),
    colorBubbleActive: rgba(92, 104, 156, 0.6),
    colorScrollbar: #606071,
    colorFitness: #ffffff,
    colorHighlightGutterSelected: rgba(68, 79, 97, 0.65)
  ),
  limitless: (
    colorPrimary: $color-green,
    colorPrimaryHover: $color-green-hover,
    colorAccent: $color-dark-green,
    colorAccentHover: $color-dark-green-hover,
    colorBackground: #232329,
    colorBackgroundAlt: #808080,
    colorBackgroundBody: #2a2a31,
    colorText: #91BE23,
    colorTextAdditional: #F4F4F4,
    cornerLogoImg: url(../../shared/img/logo/limitless/Limitless-Amazon-AdTech-Platform-Logo-Wide-Bright-Green-900x200-20210209.png),
    largeLogoImg: url(../../shared/img/logo/limitless/Limitless-Amazon-AdTech-Platform-Logo-Wide-Bright-Green-900x200-20210209.png),
    colorHover: #38373f,
    colorBorder: #333246,
    colorIcon: #605f7b,
    imgInvert: invert(100%),
    colorFieldsBorder: #33333a,
    colorBubble: rgba(68, 79, 97, 0.65),
    colorBubbleActive: rgba(92, 104, 156, 0.6),
    colorScrollbar: #606071,
    colorFitness: #ffffff,
    colorHighlightGutterSelected: rgba(68, 79, 97, 0.65)
  ),
  aj-light: (
    colorPrimary: $color-orange,
    colorPrimaryHover: $color-orange-hover,
    colorAccent: $color-red,
    colorAccentHover: $color-red-hover,
    colorBackground: white,
    colorBackgroundAlt: white,
    colorBackgroundBody: #f2f4f7,
    colorText: #646777,
    colorTextAdditional: #646777,
    cornerLogoImg: url(../../shared/img/logo/adjuggernaut/logo_light.png),
    largeLogoImg: url(../../shared/img/logo/adjuggernaut/logo_light.png),
    colorHover: #fafbfe,
    colorBorder: #eff1f5,
    colorIcon: #dddddd,
    imgInvert: invert(0%),
    colorFieldsBorder: #f2f4f7,
    colorBubble: rgba(242, 244, 247, 0.65),
    colorBubbleActive: rgba(234, 238, 255, 0.6),
    colorScrollbar: #B4BFD0,
    colorFitness: #646777,
    colorHighlightGutterSelected: rgba(255,177,83, 0.65)
  ),
  aj-dark: (
    colorPrimary: $color-orange,
    colorPrimaryHover: $color-orange-hover,
    colorAccent: $color-red,
    colorAccentHover: $color-red-hover,
    colorBackground: #232329,
    colorBackgroundAlt: #808080,
    colorBackgroundBody: #2a2a31,
    colorText: #dddddd,
    colorTextAdditional: #999999,
    cornerLogoImg: url(../../shared/img/logo/adjuggernaut/logo_dark.png),
    largeLogoImg: url(../../shared/img/logo/adjuggernaut/logo_dark.png),
    colorHover: #38373f,
    colorBorder: #333246,
    colorIcon: #605f7b,
    imgInvert: invert(100%),
    colorFieldsBorder: #33333a,
    colorBubble: rgba(68, 79, 97, 0.65),
    colorBubbleActive: rgba(92, 104, 156, 0.6),
    colorScrollbar: #606071,
    colorFitness: #ffffff,
    colorHighlightGutterSelected: rgba(68, 79, 97, 0.65)
  ),
  aj-limitless: (
    colorPrimary: $color-orange,
    colorPrimaryHover: $color-orange-hover,
    colorAccent: $color-red,
    colorAccentHover: $color-red-hover,
    colorBackground: #232329,
    colorBackgroundAlt: #808080,
    colorBackgroundBody: #2a2a31,
    colorText: #ffb153,
    colorTextAdditional: #f47b50,
    cornerLogoImg: url(../../shared/img/logo/adjuggernaut/logo_dark.png),
    largeLogoImg: url(../../shared/img/logo/adjuggernaut/logo_dark.png),
    colorHover: #38373f,
    colorBorder: #333246,
    colorIcon: #605f7b,
    imgInvert: invert(100%),
    colorFieldsBorder: #33333a,
    colorBubble: rgba(68, 79, 97, 0.65),
    colorBubbleActive: rgba(92, 104, 156, 0.6),
    colorScrollbar: #606071,
    colorFitness: #ffffff,
    colorHighlightGutterSelected: rgba(68, 79, 97, 0.65)
  ),
  ar-light: (
    colorPrimary: $color-purple,
    colorPrimaryHover: $color-purple-hover,
    colorAccent: $color-alt-green,
    colorAccentHover: $color-alt-green-hover,
    colorBackground: white,
    colorBackgroundAlt: white,
    colorBackgroundBody: #f2f4f7,
    colorText: #646777,
    colorTextAdditional: #646777,
    cornerLogoImg: url(../../shared/img/logo/adrev/logo_light.png),
    largeLogoImg: url(../../shared/img/logo/adrev/logo_light.png),
    colorHover: #fafbfe,
    colorBorder: #eff1f5,
    colorIcon: #dddddd,
    imgInvert: invert(0%),
    colorFieldsBorder: #f2f4f7,
    colorBubble: rgba(242, 244, 247, 0.65),
    colorBubbleActive: rgba(234, 238, 255, 0.6),
    colorScrollbar: #B4BFD0,
    colorFitness: #646777,
    colorHighlightGutterSelected: rgba(145, 190, 35, 0.65)
  ),
  ar-dark: (
    colorPrimary: $color-purple,
    colorPrimaryHover: $color-purple-hover,
    colorAccent: $color-alt-green,
    colorAccentHover: $color-alt-green-hover,
    colorBackground: #232329,
    colorBackgroundAlt: #808080,
    colorBackgroundBody: #2a2a31,
    colorText: #dddddd,
    colorTextAdditional: #999999,
    cornerLogoImg: url(../../shared/img/logo/adrev/logo_dark.png),
    largeLogoImg: url(../../shared/img/logo/adrev/logo_dark.png),
    colorHover: #38373f,
    colorBorder: #333246,
    colorIcon: #605f7b,
    imgInvert: invert(100%),
    colorFieldsBorder: #33333a,
    colorBubble: rgba(68, 79, 97, 0.65),
    colorBubbleActive: rgba(92, 104, 156, 0.6),
    colorScrollbar: #606071,
    colorFitness: #ffffff,
    colorHighlightGutterSelected: rgba(68, 79, 97, 0.65)
  ),
  ar-limitless: (
    colorPrimary: $color-purple,
    colorPrimaryHover: $color-purple-hover,
    colorAccent: $color-alt-green,
    colorAccentHover: $color-alt-green-hover,
    colorBackground: #232329,
    colorBackgroundAlt: #808080,
    colorBackgroundBody: #2a2a31,
    colorText: #73C947,
    colorTextAdditional: #43A040,
    cornerLogoImg: url(../../shared/img/logo/adrev/logo_dark.png),
    largeLogoImg: url(../../shared/img/logo/adrev/logo_dark.png),
    colorHover: #38373f,
    colorBorder: #333246,
    colorIcon: #605f7b,
    imgInvert: invert(100%),
    colorFieldsBorder: #33333a,
    colorBubble: rgba(68, 79, 97, 0.65),
    colorBubbleActive: rgba(92, 104, 156, 0.6),
    colorScrollbar: #606071,
    colorFitness: #ffffff,
    colorHighlightGutterSelected: rgba(68, 79, 97, 0.65)
  )
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

$directions: (
  ltr: (
    direction: ltr,

    right: right,
    left: left,

    margin-right: 'margin-right',
    margin-left: 'margin-left',

    padding-left: 'padding-left',
    padding-right: 'padding-right',

    border-top-left-radius: 'border-top-left-radius',
    border-bottom-left-radius: 'border-bottom-left-radius',

    border-top-right-radius: 'border-top-right-radius',
    border-bottom-right-radius: 'border-bottom-right-radius',

    border-left: 'border-left',
    border-right: 'border-right',

    translate: 'translate(-50%, -50%);',
    mirrorY: 'scale(1, 1)',

    border-right-color: 'border-right-color',

    transform-sidebar: 'rotate(90deg)',

    flex-flow: row nowrap,
    row: 'row',

    sidebar-close: translateX(0),
    sidebar-no-desktop: translateX(calc(0%)),
    '-': '-',
  ),
  rtl: (
    direction: rtl,

    right: left,
    left: right,

    margin-right: 'margin-left',
    margin-left: 'margin-right',

    padding-left: 'padding-right',
    padding-right: 'padding-left',

    border-top-left-radius: 'border-top-right-radius',
    border-bottom-left-radius: 'border-bottom-right-radius',

    border-top-right-radius: 'border-top-left-radius',
    border-bottom-right-radius: 'border-bottom-left-radius',

    border-left: 'border-right',
    border-right: 'border-left',

    translate: 'translate(50%, -50%);',
    mirrorY: 'scale(1, -1)',

    border-right-color: 'border-left-color',
    transform-sidebar: 'rotate(-90deg)',

    flex-flow: row-reverse nowrap,
    row: 'row-reverse',

    sidebar-close: translateX(0),
    sidebar-no-desktop:  translateX(calc(100%)),
    '-': '+',
  )
);

$directions: (
  ltr: (
    direction: ltr,

    right: right,
    left: left,

    margin-right: 'margin-right',
    margin-left: 'margin-left',

    padding-left: 'padding-left',
    padding-right: 'padding-right',

    border-top-left-radius: 'border-top-left-radius',
    border-bottom-left-radius: 'border-bottom-left-radius',

    border-top-right-radius: 'border-top-right-radius',
    border-bottom-right-radius: 'border-bottom-right-radius',

    border-left: 'border-left',
    border-right: 'border-right',

    translate: 'translate(-50%, -50%);',
    mirrorY: 'scale(1, 1)',

    border-right-color: 'border-right-color',

    transform-sidebar: 'rotate(90deg)',

    flex-flow: row nowrap,
    row: 'row',

    sidebar-close: translateX(0),
    sidebar-no-desktop: translateX(calc(0%)),
    '-': '-',
  ),
  rtl: (
    direction: rtl,

    right: left,
    left: right,

    margin-right: 'margin-left',
    margin-left: 'margin-right',

    padding-left: 'padding-right',
    padding-right: 'padding-left',

    border-top-left-radius: 'border-top-right-radius',
    border-bottom-left-radius: 'border-bottom-right-radius',

    border-top-right-radius: 'border-top-left-radius',
    border-bottom-right-radius: 'border-bottom-left-radius',

    border-left: 'border-right',
    border-right: 'border-left',

    translate: 'translate(50%, -50%);',
    mirrorY: 'scale(1, -1)',

    border-right-color: 'border-left-color',
    transform-sidebar: 'rotate(-90deg)',

    flex-flow: row-reverse nowrap,
    row: 'row-reverse',

    sidebar-close: translateX(0),
    sidebar-no-desktop:  translateX(calc(100%)),
    '-': '+',
  )
);

@mixin directify($directions) {
  @each $direction, $map in $directions {
    .#{$direction}-support & {
      $direction-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($directions, $direction), '#{$key}');
        $direction-map: map-merge($direction-map, ($key: $value)) !global;
      }
      @content;
      $direction-map: null !global;
    }
  }
}

@function directed($key) {
  @return map-get($direction-map, $key);
}

:export {
  colorPrimary: map-get(map-get($themes, light), colorPrimary);
  colorAccent: map-get(map-get($themes, light), colorAccent);
  lightColorText: map-get(map-get($themes, light), colorText);
  darkColorText: map-get(map-get($themes, dark), colorText);
  limitlessColorText: map-get(map-get($themes, limitless), colorText);
  lightColorBackground: map-get(map-get($themes, light), colorBackground);
  darkColorBackground: map-get(map-get($themes, dark), colorBackground);
  limitlessColorBackground: map-get(map-get($themes, limitless), colorBackground);
  lightColorBackgroundAlt: map-get(map-get($themes, light), colorBackgroundAlt);
  darkColorBackgroundAlt: map-get(map-get($themes, dark), colorBackgroundAlt);
  limitlessColorBackgroundAlt: map-get(map-get($themes, limitless), colorBackgroundAlt);
  ajColorPrimary: map-get(map-get($themes, aj-light), colorPrimary);
  ajColorAccent: map-get(map-get($themes, aj-light), colorAccent);
  ajLightColorText: map-get(map-get($themes, aj-light), colorText);
  ajDarkColorText: map-get(map-get($themes, aj-dark), colorText);
  ajLimitlessColorText: map-get(map-get($themes, aj-limitless), colorText);
  ajLightColorBackground: map-get(map-get($themes, aj-light), colorBackground);
  ajDarkColorBackground: map-get(map-get($themes, aj-dark), colorBackground);
  ajLimitlessColorBackground: map-get(map-get($themes, aj-limitless), colorBackground);
  ajLightColorBackgroundAlt: map-get(map-get($themes, aj-light), colorBackgroundAlt);
  ajDarkColorBackgroundAlt: map-get(map-get($themes, aj-dark), colorBackgroundAlt);
  ajLimitlessColorBackgroundAlt: map-get(map-get($themes, aj-limitless), colorBackgroundAlt);
  arColorPrimary: map-get(map-get($themes, ar-light), colorPrimary);
  arColorAccent: map-get(map-get($themes, ar-light), colorAccent);
  arLightColorText: map-get(map-get($themes, ar-light), colorText);
  arDarkColorText: map-get(map-get($themes, ar-dark), colorText);
  arLimitlessColorText: map-get(map-get($themes, ar-limitless), colorText);
  arLightColorBackground: map-get(map-get($themes, ar-light), colorBackground);
  arDarkColorBackground: map-get(map-get($themes, ar-dark), colorBackground);
  arLimitlessColorBackground: map-get(map-get($themes, ar-limitless), colorBackground);
  arLightColorBackgroundAlt: map-get(map-get($themes, ar-light), colorBackgroundAlt);
  arDarkColorBackgroundAlt: map-get(map-get($themes, ar-dark), colorBackgroundAlt);
  arLimitlessColorBackgroundAlt: map-get(map-get($themes, ar-limitless), colorBackgroundAlt);
}