.insight {
  transition: 0.3s;

  &.insight--collapse {
    height: 108px;

    &.insight--divider .insight__title, .insight__title {
      margin-bottom: 0;

      .subhead {
        opacity: 0;
      }
    }

    &.insight--divider, &.insight--success, &.insight--primary, &.insight--warning, &.insight--danger {
      height: 72px;
    }

    .insight__body {
      padding: 35px 35px 35px 30px;
    }
  }

  &.insight--narrow {

    .insight__title {
      margin-bottom: 0;
    }

  }

  &.insight--divider {

    .insight__body {
      padding: 0;
    }

    .insight__title {
      padding: 20px 40px 15px 30px;
      margin-bottom: 15px;

      @include themify($themes) {
        border-bottom: solid 1px themed('colorBorder');
      }
    }

    .insight__btns {
      top: 20px;
    }

    .insight__content {
      padding: 0 35px 40px 30px;
    }
  }

  &.insight--success, &.insight--primary, &.insight--warning, &.insight--danger {

    .insight__body {
      padding: 0;
    }

    .insight__title {
      padding: 20px 40px 15px 30px;
      margin-bottom: 15px;

      h5 {
        color: white;
      }
    }

    .insight__btns {
      top: 20px;

      svg {
        fill: #ffffff;
      }
    }

    .insight__content {
      padding: 0 35px 40px 30px;
    }
  }

  &.insight--success {

    .insight__title {
      @include themify($themes) {
        background-color: themed('colorAccent');
      }
    }
  }

  &.insight--danger {

    .insight__title {
      background-color: $color-red;
    }
  }

  &.insight--warning {

    .insight__title {
      background-color: $color-yellow;
    }
  }

  &.insight--primary {

    .insight__title {
      @include themify($themes) {
        background-color: themed('colorPrimary');
      }
    }
  }
}

.insight__count-alert {
  width: 26px;
  height: 14px;
  background-color: red;
  color: white;
  font-weight: 400;
  padding: 3px;
  margin-left: 5px;
  line-height: 9px;
  position: relative;
  text-transform: uppercase;
  border-radius: 10px;

  span {
    position: absolute;
    left: 0;
    top: 3px;
    width: 26px;
    text-align: center;
  }
}

.insight_top_border_alert {
  border-top: 2px solid;
  border-top-color: red;
}

.insight_alert-icon {
  margin-right: 10px;
  font-size: 13px;
  line-height: 13px;
  color: red;
}

.insight_alert-img {
  font-size: 25px;
  line-height: 36px;
  height: 35px;
  vertical-align: top;
  display: inline-block;
  color: red;
}

.insight__count-performance {
  width: 26px;
  height: 14px;
  background-color: green;
  color: white;
  font-weight: 400;
  padding: 3px;
  margin-left: 5px;
  line-height: 9px;
  position: relative;
  text-transform: uppercase;
  border-radius: 10px;

  span {
    position: absolute;
    left: 0;
    top: 3px;
    width: 26px;
    text-align: center;
  }
}

.insight_top_border_performance {
  border-top: 2px solid;
  border-top-color: green;
}

.insight_performance-icon {
  margin-right: 10px;
  font-size: 13px;
  line-height: 13px;
  color: green;
}

.insight_performance-img {
  font-size: 25px;
  line-height: 36px;
  height: 35px;
  vertical-align: top;
  display: inline-block;
  color: green;
}

.insight_platform-img {
  font-size: 20px;
  line-height: 20px;
  height: 40px;
  width: 240px;
  display: inline-block;
}

.insight_bottom-blank {
  height: 200px;
  background-color: lightgray;
}

.insight__body {
  position: relative;
  padding: 30px 35px 50px 30px;
  transition: height 0.3s;
}

.insight__card_header-right {
  position: absolute;
  top: 40px;
  right: 40px;
}

.insight__btn {
  width: 14px;
  height: 14px;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-left: 10px;

  svg {
    width: 14px;
    height: 14px;
    fill: $color-additional;
  }

  &:focus {
    outline: none;
  }
}

.insight__title {
  text-transform: uppercase;
  margin-bottom: 30px;
  transition: 0.3s;

  h5 {
    font-size: 13px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    transition: 0.3s;
  }
}

.insight__label {
  left: 100%;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 13px;
  padding: 3px 10px;
  margin-left: 10px;
  @include themify($themes) {
    background-color: themed('colorAccent');
  }
}

.insight__icon {
  margin-right: 5px;
}
