.radio-btn {
  display: inline-block;
  cursor: pointer;
  padding-right: 20px;

  &:last-child {
    padding-right: 0;
  }

  &:hover {

    .radio-btn__radio-custom {
      @include themify($themes) {
        border-color: themed('colorAccent');
      }
    }

    .radio-btn__label {
      @include themify($themes) {
        color: themed('colorAccent');
      }
    }
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.4;
  }

  &.radio-btn--colored {

    .radio-btn__radio-custom {
      @include themify($themes) {
        border: 2px solid themed('colorAccent');
      }
    }
  }

  &.radio-btn--colored-click {

    .radio-btn__radio:checked + .radio-btn__radio-custom {
      @include themify($themes) {
        background: themed('colorAccent');
      }

      &::before {
        background: #ffffff;
      }
    }

    &.disabled {

      .radio-btn__radio:checked + .radio-btn__radio-custom {
        background: transparent;

        &::before {
          @include themify($themes) {
            background: themed('colorAccent');
          }
        }
      }
    }
  }

  &.radio-btn--button {
    @include themify($themes) {
      background: themed('colorAccent');
    }
    min-width: 150px;
    color: #ffffff;
    height: 24px;
    border-radius: 4px;
    transition: all 0.3s;
    display: flex;
    padding: 0 6px;
    width: 100%;

    .radio-btn__radio-custom {
      display: none;
    }

    .radio-btn__label-svg {
      margin: auto 4px auto auto;
      height: 16px;
      line-height: 1;

      svg {
        fill: #ffffff;
        width: 14px;
        height: 14px;
      }

      .radio-btn__label-check {
        display: none;
      }
    }

    .radio-btn__radio:checked ~ .radio-btn__label-svg {

      .radio-btn__label-check {
        display: block;
      }

      .radio-btn__label-uncheck {
        display: none;
      }
    }

    .radio-btn__label {
      margin: auto auto auto 0;
      padding: 0;
      color: #ffffff;
    }

    &:hover {
      @include themify($themes) {
        background: themed('colorAccentHover');
      }

      .radio-btn__label {
        color: #ffffff;
      }
    }
  }
}

.radio-btn__radio {
  display: none;
}

.radio-btn__radio-custom {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: relative;
  transition: all 0.3s;

  @include themify($themes) {
    border: 1px solid themed('colorIcon');
  }
}

.radio-btn__radio:checked + .radio-btn__radio-custom {
  @include themify($themes) {
    border-color: themed('colorAccent');
  }
}

.radio-btn__label {
  line-height: 18px;
  padding-left: 9px;
  transition: all 0.3s;

  @include themify($themes) {
    color: themed('colorText');
  }
}

.radio-btn__radio-custom,
.radio-btn__label {
  display: inline-block;
  vertical-align: middle;
}

.radio-btn__radio:checked + .radio-btn__radio-custom::before {
  content: "";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  top: calc(50% - 3px);
  left: calc(50% - 3px);
  @include themify($themes) {
    background: themed('colorAccent');
  }
  border-radius: 50%;
}